import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
  apiUrl: string = 'https://admin.turkanajoyeria.com';
  permisosdisponibles = [
    'CATEGORIAS',
    'DASHBOARD',
    'DESCUENTOS',
    'PRODUCTOS',
    'USUARIOS',
    'VENTAS'
  ];
}
