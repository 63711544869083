import { Component, OnInit } from '@angular/core';

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  children?: ChildrenItems[];
  permission?: string;
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab?: string;
  type?: string;
  permission?: string;
  icontype: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Inicio',
    type: 'link',
    icontype: 'nc-icon nc-bank'
  },
  {
    path: '/catalogos/ventas',
    title: 'Ventas',
    type: 'link',
    icontype: 'fal fa-cash-register',
    permission: "VENTAS"
  },
  // {
  //   path: '/reportes',
  //   title: 'Reportes',
  //   type: 'sub',
  //   icontype: 'fal fa-user-chart',
  //   children: [],
  //   permission: "REPORTES",
  // },
  {
    path: '/catalogos',
    title: 'Catálogos',
    type: 'sub',
    icontype: 'fal fa-archive',
    children: [
      { path: 'categorias', title: 'Categorías', icontype: 'fal fa-book', permission: 'CATEGORIAS' },
      { path: 'descuentos', title: 'Descuentos', icontype: 'fal fa-percent', permission: 'DESCUENTOS' },
      { path: 'productos', title: 'Productos', icontype: 'fal fa-bags-shopping', permission: 'PRODUCTOS' },
      { path: 'usuarios', title: 'Usuarios', icontype: 'fal fa-users', permission: 'USUARIOS' }
    ]
  },
];

@Component({

  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  public menuItems: any[];
  isNotMobileMenu() {
    if (window.outerWidth > 991) {
      return false;
    }
    return true;
  }
  private token = JSON.parse(localStorage.getItem('jwtToken'));
  public perm = this.token.user.permisos;
  public user = this.token.user;

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
}
